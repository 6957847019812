* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-family: "Public Sans", sans-serif;
}
:root {
  --admin-primary-color: #fff8e9;
  --admin-secondary-color: #8b714a;
  --admin-teritary-color: #d7ac6c;
  --admin-accent-color: #000;
  --admin-background-color: #ad8c5c;
  --admin-text-color: #fff;
}
.wizard-container {
  max-width: 600px;
  margin: 0 auto;
  padding: 20px;
  border: 1px solid #eaeaea;
  border-radius: 5px;
  background-color: #fff;
  text-align: center;
  align-items: center !important;
}

.wizard-container h2 {
  font-size: 24px;
  margin-bottom: 15px;
}

.wizard-container p {
  font-size: 16px;
  color: #555;
  margin-bottom: 20px;
}