* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-family: "Nunito Sans", sans-serif;
}

.nav-link.active {
  color: #6182e6 !important;
}

.navbar-vertical.navbar-light .navbar-nav>.nav-item>.nav-link:hover {
  background-color: #d8e1ff;
  color: #000;
  cursor: pointer;
}

.navbar-vertical.navbar-light .navbar-nav>.nav-item>.nav-link:hover i,
.navbar-vertical.navbar-light .navbar-nav>.nav-item>.nav-link.active i {
  color: #000;
  cursor: pointer;
}


.nav-link {
  font-weight: small !important;
}

.minHeight {
  min-height: 85vh;
}

.css-1u4zpwo-MuiSvgIcon-root-MuiStepIcon-root.Mui-active {
  color: #a070ff !important;
}

.css-1u4zpwo-MuiSvgIcon-root-MuiStepIcon-root {
  color: #a070ff !important;
}

.linkPadding {
  margin-right: 5px;
}

.circle {
  width: 25px;
  height: 25px;
  border: none;
  cursor: pointer;
}

.circle::-webkit-color-swatch {
  border-radius: 50%;
}

/* Style for the checkbox container */
.checkbox-container {
  display: flex;
  align-items: center;
}

/* Style for the checkbox itself */
.M-S {
  width: 0;
  height: 0;
  visibility: hidden;
}

/* Style for the square mark */
.custom-checkbox {
  width: 20px;
  height: 20px;
  border: 1px solid #e6e3e3;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  border-radius: 5px;
}

/* Style for the square inside the square mark */
.inner-square {
  width: 7.5px;
  height: 7.5px;
  border: 1px solid white;
  background-color: transparent;
  display: none;
}


/* Style to show inner square when checkbox is checked */
.M-S:checked+.custom-checkbox .inner-square {
  display: block;
  background-color: white;
}

.M-S:checked+.custom-checkbox {
  background-color: green;
  border: 1px solid white;
  border-radius: 0px;
}

.Sunday {
  width: 0;
  height: 0;
  visibility: hidden;
}

.Sunday:checked+.custom-checkbox .inner-square {
  display: block;
  background-color: white;
}

.Sunday:checked+.custom-checkbox {
  background-color: orange;
  border: 1px solid white;
  border-radius: 0px;
}

.headingColor {
  color: black;
  font-size: x-large;
}

/* Loader Css  */
.loader-container {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 95vh;
}

.loading {
  --speed-of-animation: 0.9s;
  --gap: 6px;
  --first-color: #4c86f9;
  --second-color: #49a84c;
  --third-color: #f6bb02;
  --fourth-color: #f6bb02;
  --fifth-color: #2196f3;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100px;
  gap: 6px;
  height: 100px;
}


/* All save , update some buttons custom css */

.btn-button {
  color: #fff;
  padding: 0.5rem 0.9rem;
  font-weight: 600;
  background-color: linear-gradient(135deg, #a070ff, #b987ff);
  border-color: #a070ff;
}

.btn-button i {
  font-weight: 600;
}

.btn-button:hover {
  color: #fff;
  background-color: linear-gradient(135deg, #a070ff, #b987ff);
  border-color: #a070ff;
}

.btn-check:focus+.btn-button,
.btn-button:focus {
  color: #fff;
  background-color: linear-gradient(135deg, #a070ff, #b987ff);
  border-color: #a070ff;
  box-shadow: 0 0 0 0.25rem #4066D5a1;
}

.btn-check:checked+.btn-button,
.btn-check:active+.btn-button,
.btn-button:active,
.btn-button.active,
.show>.btn-button.dropdown-toggle {
  color: #fff;
  background-color: linear-gradient(135deg, #a070ff, #b987ff);
  border-color: #a070ff;
}

.btn-check:checked+.btn-button:focus,
.btn-check:active+.btn-button:focus,
.btn-button:active:focus,
.btn-button.active:focus,
.show>.btn-button.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.25rem #4066D5a1;
}

.btn-button:disabled,
.btn-button.disabled {
  color: #fff;
  background-color: linear-gradient(135deg, #a070ff, #b987ff);
  border-color: #a070ff;
}

.btn-button-check:checked+.btn-button,
.btn-button.active,
.btn-button.show,
.btn-button:first-child:active,
:not(.btn-button-check)+.btn-button:active {
  background-color: linear-gradient(135deg, #a070ff, #b987ff);
  border-color: #006e8fdc;
  color: #fff;
}

/* Table Css */

#Table_Css table thead {
  background: #e4e6eb;

}

#Table_Css table thead tr th {
  outline: none !important;
  font-size: 11px !important;
}

#Table_Css table tbody tr {
  background-color: #ffffff !important;
}

#Table_Css table tbody tr td {
  background: #ffffff !important;
  padding: 20px 10px !important;
}

/* Filter Search input */
div.dt-container .dt-search {
  text-align: right !important;
}

div.dt-container .dt-search input {
  outline: none !important;
}

/* Buttons in Page */
div.dt-container .dt-paging {
  text-align: right !important;
}

div.dt-container .dt-paging .dt-paging-button {
  padding: 0px !important;
}

div.dt-container .dt-paging .dt-paging-button {
  padding: 0px !important;
}

div.dt-container .dt-paging .dt-paging-button :hover {
  padding: 2px !important;
}

/* entries per page */
div.dt-container select.dt-input {
  margin-right: 10px;
  padding: 3px;
}

@media (min-width: 992px) {
  .logo_ats {
    position: sticky !important;
    top: 0;
    z-index: 20;
    background: "#A5E5E5" !important;
  }
}

.sortdown::after {
  display: none;
}

.logutBtn :hover {
  /* color: #ffffff !important; */
  background-color: #d8e1ff;
}

.sendMail:hover {
  background: white;
  color: #a070ff;
  border: 1px solid #6386eecc !important;
}

.emailHeader {
  width: 70% !important;
}

.emailHeader .offcanvas-header {
  padding: 0px;
  padding-left: 15px;
  background-color: linear-gradient(135deg, #a070ff, #b987ff);
  color: #fff;
  border-bottom: 1px solid #424242;
}


/* Required Skills */
.tags-inp-container {
  width: min(100vw, 100%);
}

.tag-item {
  background-color: linear-gradient(135deg, #a070ff, #b987ff);
  color: white;
  display: inline-block;
  padding: .2em .6em;
  border-radius: 20px;
}

.tags-input {
  flex-grow: 1;
  padding: .5em 0;
  border: none !important;
  outline: none !important;
}

.background-color {
  background-color: #1A516B;
  padding: 4px;
  color: #fcf9f9;
}

.delete:hover {
  background-color: red !important;
  color: white !important;
}

.removeHrMargin {
  margin: 0;
}

.Loader-Div {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100vh;

  margin: 0;
}

svg {
  animation: rotate 2.5s cubic-bezier(0.35, 0.04, 0.63, 0.95) infinite;
  transform-origin: 50% 65%;
}

svg polygon {
  stroke-dasharray: 17;
  animation: dash 2.5s cubic-bezier(0.35, 0.04, 0.63, 0.95) infinite;
}

@keyframes dash {
  to {
    stroke-dashoffset: 136;
  }
}


/* New Admin Css */
.admin-container {
  display: flex;
  flex-direction: column;
  height: 100vh;
}

.admin-main-content {
  display: flex;
  flex: 1; /* Make it take up the full height */
}

.admin-header {
  flex-shrink: 0; /* Keep the header size fixed */
}

.admin-sidebar {
  width: 250px; /* Fixed width for sidebar */
  flex-shrink: 0;
  background-color: #f8f9fa; /* Optional: Sidebar background color */
}

.admin-main-routes {
  flex-grow: 1; /* This takes up the remaining space */
  padding: 20px;
  background-color: #ffffff; /* Optional: Main content background */
  overflow-y: hidden;
}

/* Optional: Styling for sidebar */
.admin-sidebar {
  position: fixed; /* Make sidebar fixed */
  left: 0;
  top: 80px; /* Adjust according to the height of your header */
  bottom: 0;
  overflow-y: auto;
}

/* Optional: Styling for header */
.admin-header {
  width: 100%;
  height: 80px; /* Fixed height for header */
  background-color: #343a40; /* Adjust the background color as needed */
  color: white; /* Adjust text color */
}

/* Badges Custom Colors */
.badge-approved {
  background: #b5f5e0;
  color: #20c997;
  padding: 4px 10px;
  border-radius: 20px;
  font-size: 12px;
  font-weight: 700;
}

.badge-pending {
  background: #e0e7ff;
  color: #5c60f5;
  padding: 4px 10px;
  border-radius: 20px;
  font-size: 12px;
  font-weight: 700;
}

.badge-rejected {
  background: #ffccd5;
  color: #ee3060;
  padding: 4px 10px;
  border-radius: 20px;
  font-size: 12px;
  font-weight: 700;
}

/* dashbord btn */
.btn-dashbord {
  background: linear-gradient(135deg, #a070ff, #b987ff);
  color: white;
  font-weight: 700;
  padding: 5px 15px;
  text-decoration: none;
  margin-top: 5px;
  border: none;
  border-radius: 4px;
  transition: background 0.3s ease; /* Smooth transition */
}

.btn-dashbord:hover {
  background: linear-gradient(135deg, #8c54e8, #a070ff); /* Slightly darker gradient on hover */
  color: white; 
}

.btn-dashbord.active {
  background: linear-gradient(135deg, #a070ff, #b987ff);
  color: white;
  font-weight: 700;
  padding: 5px 15px;
  text-decoration: none;
}

@media (max-width: 992px) {
  .btn-dashbord {
    background: linear-gradient(135deg, #a070ff, #b987ff);
    color: white;
    font-weight: 700;
    padding: 5px 15px;
    text-decoration: none;
    margin-top: 5px;
    border: none;
    border-radius: 4px;
    transition: background 0.3s ease; /* Smooth transition */
    margin-left: 30px;
  }

  .btn-dashbord:hover {
    background: linear-gradient(135deg, #8c54e8, #a070ff); /* Slightly darker gradient on hover */
    color: white; 
  }
  
  .btn-dashbord.active {
    background: linear-gradient(135deg, #a070ff, #b987ff);
    color: white;
    font-weight: 700;
    padding: 5px 15px;
    text-decoration: none;
  }
}

.headColor {
  color: #905ef5;
  font-weight: 400 !important;
  font-size: 19px;
  margin-bottom: 0px;
}

/* Profile Section */
.profile-section {
  text-align: center;
  margin-bottom: 20px;
}

.profile-picture {
  width: 170px;
  height: 170px;
  border-radius: 50%;
  object-fit: cover;
  border: 5px solid #a070ff;
}

/* Loader */
.loader-container {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
}

.custom-input {
  border: none;
  border-bottom: 1px solid #ccc;
  border-radius: 0;
  padding: 8px 5px;
  outline: none;
  box-shadow: none !important;
}

.custom-input:focus {
  border-bottom: 2px solid #007bff;
}
.step-container {
  position: relative;
}

.custom-tooltip-container {
  position: relative;
  display: inline-block;
}

.custom-tooltip {
  visibility: hidden;
  background-color: #333;
  color: #fff;
  text-align: center;
  border-radius: 4px;
  padding: 5px;
  position: absolute;
  bottom: 150%; /* Adjust tooltip position */
  left: 50%;
  transform: translateX(-50%);
  white-space: nowrap;
  z-index: 10;
  opacity: 0;
  transition: opacity 0.3s ease;
}

.custom-tooltip-container:hover .custom-tooltip {
  visibility: visible;
  opacity: 1;
}

.custom-close-btn {
  color: #007bff; /* Set your desired color */
  font-size: 0.8rem; /* Set small size */
  box-shadow: none !important; /* Remove shadow */
  background-color: transparent; /* Ensure background is transparent */
  border: none; /* Remove any border */
}

.custom-close-btn:hover {
  color: #0056b3; /* Optional hover effect */
}

.bg-fc td {
  background-color: white !important;
}

.bg-fc td:hover {
  background-color: whitesmoke !important;
}
.file-link {
  color: black;
  font-size: 12px;
}

.file-icon {
  color: black;
  font-size: 1.2rem;
}

.file-icon:hover {
  color: #007bff; 
}
